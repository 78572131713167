import { template as template_d9b3e7f4e0fe44b3b1233a5dfddad850 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d9b3e7f4e0fe44b3b1233a5dfddad850(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
