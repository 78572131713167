import { template as template_7c60ec28835c42fbb81a6f3c646d0243 } from "@ember/template-compiler";
const WelcomeHeader = template_7c60ec28835c42fbb81a6f3c646d0243(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
